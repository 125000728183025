import React from "react";

function Projects() {
  return (
    <div>
      <div className="mt-24 lg:mt-32">
        <div className="font-a py-4">Projects</div>
        <ul>
          <li className="border-t border-[#696969e7] p-1 md:p-4 hover:text-[white] hover:bg-[#0f0f0f]">
            <a
              className="flex flex-row justify-between text-sm md:text-base"
              href="https://www.blackrabbit.so"
              target="_blank"
            >
              <div>BlackRabbit</div>
              <div className="w-[55%] lg:w-auto">
                Digital asset research and advisory
              </div>
            </a>
          </li>
          <li className="border-y border-[#696969e7] p-1 md:p-4 hover:text-[white] hover:bg-[#0f0f0f]">
            <a
              className="flex flex-row justify-between text-sm md:text-base"
              href="https://www.myaiclub.com/"
              target="_blank"
            >
              <div>AiClub </div>
              <div className="w-[55%] lg:w-auto">
                Curated genAi community for founders, builders, researchers and
                more
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Projects;
