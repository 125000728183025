import React from "react";

function Soon() {
  return (
    <div>
      <div className="mt-12 lg:mt-32">
        <div className="font-a py-4">Coming Soon</div>
        <ul>
          <li className="p-1 md:p-4 text-sm md:text-base">
            Something in EdTech.
          </li>
          <li className="p-1 md:p-4 text-sm md:text-base">
            Something in WealthTech.
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Soon;
