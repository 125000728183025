import React from "react";

function Header() {
  return (
    <div>
      <div className="font-a text-6xl md:text-8xl pt-8 w-full max-w-[40rem] text-left">
        A9A STUDIO
      </div>
      <div className="font-d text-xs md:text-lg pt-2 w-full text-left m-auto tracking-[.18rem] md:tracking-[.5rem]">
        Emerging tech product studio
      </div>
      <div className="font-d text-left text-lg mt-16 lg:mt-32 md:max-w-[70%] max-w-auto tracking-wide">
        Builders see problems in the world and tackle them with patience,
        courage and insight.
        <br />
        For our users & just users. We take ideas from concept to company. We
        design, build and launch companies.
        <br />
        We are making the world a better place with emerging technology. We're
        building consumer products with AI & WEB3 in their DNA.
      </div>
    </div>
  );
}

export default Header;
